/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/themes/jeu-aide-machine-theme.module.css';

const propTypes = {
    className: PropTypes.string,
    containerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    children: PropTypes.node.isRequired,
};

function JeuAideMachineTheme({ children = null, className = null, containerRef, ...props }) {
    return (
        <div className={classNames([className, styles.container])} ref={containerRef} {...props}>
            {children}
        </div>
    );
}

// eslint-disable-next-line @eslint-react/no-prop-types
JeuAideMachineTheme.propTypes = propTypes;

const ForwardedJeuAideMachineTheme = React.forwardRef((props, ref) => (
    <JeuAideMachineTheme containerRef={ref} {...props} />
));
ForwardedJeuAideMachineTheme.displayName = 'ForwardedJeuAideMachineTheme';

export default ForwardedJeuAideMachineTheme;
